import React, { useEffect, useState } from "react";
import { fetchVideo } from "../../services/generateVideo.service";
import { fetchFile } from "../../services/getFIleData";
import { Button } from "react-bootstrap";
import { IoVideocamOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { StoreContext } from "../../store/StoreContext";
import { FiSend } from "react-icons/fi";
import ShareModal from "../../shared/shareModal";
const API_URL = process.env.REACT_APP_API_URL;
const SavedVideo = (props: any) => {
  const navigate = useNavigate();
  const scriptContent = props.script;
  const [videoData, setVideoData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [fileData, setFileData] = useState<any>(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModalScript, setShowModalScript] = useState(false);
  const [file, setFile] = useState(''); // Set this to your file value if needed

  const handleOpenModal = () => {
    setShowModalScript(true);
  };

  const handleCloseModal = () => {
    setShowModalScript(false);
  };
  const store = React.useContext(StoreContext);
  const [duration, setDuration] = useState<number | null>(null);
  const userId = localStorage.getItem('userId')
  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await fetchFile("videos");
        setFileData(response);
      } catch (error) {
        console.log("Error fetching video data.");
      }
    };
    getFile();
  }, []);
  const handleClick = (event: any, file: any, index: number) => {
    setSelectedVideo(file);
    console.log("file", file);

    store.setShortUrl(file);
    const videoElement = document.createElement('video');
    videoElement.src = file;
    videoElement.addEventListener('loadedmetadata', () => {
      setDuration(videoElement.duration);
    });
    store.addVideoUrl(file)
    // store.addEditorElement(file);
    store.setMaxTime(Number(60) * 1000);
    navigate("/editor");
  };
  console.log("video duration", duration);

  // console.log("video data",videoData.data.hits[0].videos.small.url)
  return (
    <>
      {/* <div>Generated Videos</div> */}
      <h1 className="px-5 my-1 font-semibold text-xl">Saved Videos</h1>
      <div className="max-w-[1640px] mx-auto p-4 grid md:grid-cols-3 gap-6">
        {fileData?.map((file: any, index: any) => (
          <div key={index} className="flex flex-col items-center">
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}> {/* 16:9 Aspect Ratio */}
              <video
                controls
                className="absolute top-0 left-0 w-full h-full object-cover max-h-60"
                style={{ outline: "none" }} // Optionally remove focus outline
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData(
                    "url",
                    `${API_URL}/uploads/${userId}/${file}`
                  );
                }}
              >
                <source
                  src={`${API_URL}/uploads/${userId}/${file}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="mt-4">
             {/* <button
                type="button"
                className="text-white bg-red-500 inline-flex items-center justify-center rounded-md p-2.5 gap-2 mr-5"
                onClick={(e) => handleClick(e, `${API_URL}/uploads/${userId}/${file}`, index)}
              >
                <IoVideocamOutline />
                Create Yt Short
              </button> */}
              <button
                type="button"
                className="text-white bg-green-500 inline-flex items-center justify-center rounded-md p-2.5 gap-2"
                onClick={handleOpenModal}
              >
                <FiSend />
                Share
              </button>
            </div>
            {showModalScript && (
              <ShareModal
                file={`${API_URL}/uploads/${userId}/${file}`}
                script="Your script content here" // Pass the script as needed
                handleClose={handleCloseModal} // Pass the handleClose function to ShareModal
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default SavedVideo;
