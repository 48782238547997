import { AbsoluteFill, Img, staticFile, Sequence,Audio } from 'remotion';

export const MyComposition = () => {
  return (
    <>
      <AbsoluteFill>
        <Sequence>
          <Img src={staticFile('/demo/1.jpeg')} />
          <Img src={staticFile('/demo/2.jpeg')} />
          <Img src={staticFile('/demo/3.jpeg')} />
        </Sequence>
        {/* <Sequence>
          <Img src={staticFile('/demo/2.jpeg')} />
        </Sequence> */}
        <Sequence from={60} durationInFrames={40}>
          <h1>This text appears after 60 frames!</h1>
        </Sequence>
        <Audio src={staticFile('/demo/amalgam.mp3')} />
      </AbsoluteFill>
    </>
  );
};
