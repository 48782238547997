import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa';
import { FiClipboard } from 'react-icons/fi';
const ShareModal = (props: any) => {
    const { script, handleClose } = props;
    const [copySuccess, setCopySuccess] = useState('');
    const url=props.file;
    const copyToClipboard = () => {
      navigator.clipboard.writeText(url)
        .then(() => {
          setCopySuccess('Copied!');
          setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
        })
        .catch((error) => {
          console.error('Failed to copy: ', error);
          setCopySuccess('Failed to copy');
        });
    };

    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                        style={{ width: "800px" }}
                    >
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h6 className="text-2xl text-black">Share Video</h6>
                            <div className="flex items-center justify-end border-blueGray-200 rounded-b">
                                <button
                                    className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={handleClose}
                                >
                                    X
                                </button>
                            </div>
                        </div>
                        {/*body*/}
                        <div className="relative px-6 pt-3 flex-auto">
                            <h2 className="font-bold">Share To</h2>
                        </div>
                        <div className="flex items-center space-x-2 p-4 mb-2">
                            <input
                                type="text"
                                value={url}
                                readOnly
                                className="flex-1 p-2 border border-gray-300 rounded"
                                aria-label="URL"
                            />
                            <button
                                onClick={copyToClipboard}
                                className="flex-shrink-0 bg-blue-500 text-white p-2 rounded flex items-center"
                                aria-label="Copy URL"
                            >
                                <FiClipboard size={16} />
                                {copySuccess && <span className="ml-2">{copySuccess}</span>}
                            </button>
                        </div>
                        {/* <div className="relative p-6 flex-auto">
                            <h2 className="font-bold">Share To</h2>
                        </div> */}
                        <div className="flex justify-center items-center space-x-4 mb-12">
                            <a
                                href="https://www.youtube.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-red-600 hover:text-red-800 transition-colors duration-300"
                            >
                                <FaYoutube size={30} />
                            </a>
                            <a
                                href="https://www.tiktok.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-black hover:text-gray-800 transition-colors duration-300"
                            >
                                <FaTiktok size={30} />
                            </a>
                            <a
                                href="https://www.instagram.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-purple-600 hover:text-purple-800 transition-colors duration-300"
                            >
                                <FaInstagram size={30} />
                            </a>
                        </div>
                        {/*footer*/}
                        {/* <div className="flow-root px-3 py-2">
                            <button
                                type="button"
                                className="inline-item float-right px-3 py-2 mx-0.5 text-white bg-sky-400 text-sm font-medium focus:ring-4 focus:ring-blue-200"
                            >
                                Generate Video
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    );
};

export default ShareModal;
