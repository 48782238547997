"use client";
import React, { useEffect, useState } from "react";
import { StoreContext } from "../../store/StoreContext";
import { observer } from "mobx-react";
import { VideoResource } from "../entity/VideoResource";
import { UploadButton } from "../shared/UploadButton";
import { fetchVideo } from "../../services/generateVideo.service";
import localforage from 'localforage';

// Initialize localForage
localforage.config({
  name: 'videoStorage'
});

export const VideoResourcesPanel = observer((props: any) => {
  const [imageBlobUrls, setImageBlobUrls] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const scriptContent = props.script;
  const store = React.useContext(StoreContext);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    store.addVideoResource(URL.createObjectURL(file));
  };
  const [videoData, setVideoData] = useState<any>(null);
  useEffect(() => {
    const getVideo = async () => {
      try {
        setLoading(true);
        const response = await fetchVideo(scriptContent);
        console.log("response", response.data.hits);
        const urls = response?.data?.hits?.map((photo: any) => photo.videos.tiny.url);
        console.log("urls", urls);
        if (!Array.isArray(urls)) {
          throw new Error('Response does not contain an array of URLs');
        }

        const blobUrls: string[] = await Promise.all(
          urls.map(async (url: string) => {
            const data = await fetch(url);
            const blob = await data.blob();
            const blobId = '7b006c06-dd45-46b8-b1b3-b1e7711cde5c-' + Math.random().toString(36).substr(2, 9);

            await localforage.setItem(blobId, blob);

            return URL.createObjectURL(blob);
          })
        );

        setImageBlobUrls(blobUrls);
        setLoading(false);
      } catch (error) {
        setError("Error fetching video data.");
      }
    };
    getVideo();
  }, [scriptContent]);
  console.log("imageBlobUrls", imageBlobUrls)
  return (
    <>
      <div className="text-sm px-[16px] pt-[16px] pb-[8px] font-semibold">
        Videos
      </div>
      {store.videos.map((video, index) => {
        return <VideoResource key={video} video={video} index={index} />;
      })}
      <UploadButton
        accept="video/mp4,video/x-m4v,video/*"
        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-center mx-2 py-2 px-4 rounded cursor-pointer"
        onChange={handleFileChange}
      />
      <div>
      <h2 className="px-[16px] pt-[16px] pb-[8px] font-semibold">Generated Videos</h2>
        {loading ? (
          <div className="loader">
            <img src="https://i.gifer.com/ZZ5H.gif" alt="Loading..." width={60} />
          </div>
        ) : (
          <div>
            {imageBlobUrls.map((video: any, index: number) => (
              <VideoResource key={index} video={video} index={index} />
            ))}
          </div>
        )}

      </div>
    </>
  );
});
