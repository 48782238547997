import React, { useEffect, useState } from "react";
import { fetchVideo } from "../../services/generateVideo.service";
import { fetchFile } from "../../services/getFIleData";
const API_URL = process.env.REACT_APP_API_URL;
const Video = (props: any) => {
  const scriptContent = props.script;
  const [videoData, setVideoData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [fileData, setFileData] = useState<any>(null);

  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await fetchFile("videos");
        setFileData(response);
      } catch (error) {
        console.log("Error fetching video data.");
      }
    };
    getFile();
  }, []);

  useEffect(() => {
    const getVideo = async () => {
      try {
        const response = await fetchVideo(scriptContent);
        setVideoData(response);
      } catch (error) {
        setError("Error fetching video data.");
      }
    };
    getVideo();
  }, [scriptContent]);
  // console.log("video data",videoData.data.hits[0].videos.small.url)
  return (
    <>
      {/* <div>Generated Videos</div> */}
      <h1 className="px-2 my-1 font-semibold">Uploaded Videos</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-1 px-1">
        {fileData?.map((file: any, index: any) => (
          <div key={index} className="aspect-w-1 aspect-h-1">
            <video
              controls
              className="object-cover w-full h-full"
              style={{ outline: "none" }} // Optionally remove focus outline
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "url",
                  `${API_URL}/uploads/${file}`
                );
              }}
            >
              <source
                src={`${API_URL}/uploads/${file}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        ))}
      </div>
      <h1 className="px-2 mt-3 mb-1 font-semibold">Generated Videos</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mt-1">
        {videoData?.data?.hits?.map((video: any, index: number) => (
          <div key={index} className="aspect-w-1 aspect-h-1">
            <video
              controls
              className="object-cover w-full h-full"
              style={{ outline: "none" }} // Optionally remove focus outline
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("url", video.videos.small.url);
              }}
            >
              <source src={video.videos.small.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <iframe
              src={video.videos.small.url}
              title={`video-${index}`}
              frameBorder="0"
              className="object-cover w-full h-full"
              allowFullScreen
            ></iframe> */}
          </div>
        ))}
      </div>
    </>
  );
};

export default Video;
