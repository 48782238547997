import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface SnippetState {
    snippets: string[];
  }
  
  const initialSnippetState: SnippetState = {
    snippets: [],
  };
  
  const snippetSlice = createSlice({
    name: 'snippets',
    initialState: initialSnippetState,
    reducers: {
      saveSnippets: (state, action: PayloadAction<string[]>) => {
        state.snippets = action.payload;
      },
    },
  });
  
  export const { saveSnippets } = snippetSlice.actions;
  export default snippetSlice.reducer;