"use client";
import React, { useEffect, useState } from "react";
import { StoreContext } from "../../store/StoreContext";
import { observer } from "mobx-react";
import { ImageResource } from "../entity/ImageResource";
import { UploadButton } from "../shared/UploadButton";
import { fetchNoun } from "../../services/generateNoun.service";
import { fetchSnippet } from "../../services/generateSnippet.service";
import localforage from 'localforage';
import { saveSnippets } from "../../store/script/snippetSlice";
import { useDispatch } from "react-redux";

// Initialize localForage
localforage.config({
  name: 'imageStorage'
});

export const ImageResourcesPanel = observer((props: any) => {
  const dispatch = useDispatch();
  const [imageBlobUrls, setImageBlobUrls] = useState<string[]>([]);
  const [imageName, setImageName] = useState<string[]>([]);
  const [snip, setSnip] = useState<string[]>([]);
  const [videoData, setVideoData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const scriptContent = props.script;
  const [loading, setLoading] = useState<boolean>(false);
  const store = React.useContext(StoreContext);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    store.addImageResource(URL.createObjectURL(file));
  };
  useEffect(() => {
    const getNoun = async () => {
      try {
        setLoading(true);
        const response = await fetchNoun(scriptContent);
        // const urls = response?.photos?.photos?.map((photo: any) => photo.src.small);
        const urls = response?.results?.map((photo: any) => photo.photos.src.original);
        setImageName(response?.results?.map((photo: any) => photo.noun));

        if (!Array.isArray(urls)) {
          throw new Error('Response does not contain an array of URLs');
        }

        const blobUrls: string[] = await Promise.all(
          urls.map(async (url: string) => {
            const data = await fetch(url);
            const blob = await data.blob();
            const blobId = '7b006c06-dd45-46b8-b1b3-b1e7711cde5c-' + Math.random().toString(36).substr(2, 9);

            await localforage.setItem(blobId, blob);

            return URL.createObjectURL(blob);
          })
        );

        setImageBlobUrls(blobUrls);
        setLoading(false);
      } catch (error) {
        setError('Error fetching and processing image data.');
        console.error('Error fetching and processing image data:', error);
      }
    };

    getNoun();
  }, [scriptContent]);

  useEffect(()=>{
    const getSnip = async () => {
      try {
        setLoading(true);
        const response = await fetchSnippet(scriptContent);
        // console.log("snippets",response.snippets);
        setSnip(response.snippets);
        dispatch(saveSnippets(response.snippets));
        setLoading(false);
      } catch (error) {
        setError('Error fetching and processing image data.');
        console.error('Error fetching and processing image data:', error);
      }
    };

    getSnip();

  },[])
  const index = 0;
  // console.log("image blob", imageBlobUrls)
  return (
    <>
      <div className="text-sm px-[16px] pt-[16px] pb-[8px] font-semibold">
        Images
      </div>
      <UploadButton
        accept="image/*"
        className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold text-center mx-2 py-2 px-4 rounded cursor-pointer"
        onChange={handleFileChange}
      />
      <div >
        {store.images.map((image, index) => {
          return <ImageResource key={image} image={image} index={index} imageName={imageName[index]} />;
        })}
      </div>
      <div>
        <h2 className="px-[16px] pt-[16px] pb-[8px] font-semibold">Generated Images</h2>
        <div className="">
          {/* {videoData?.photos?.photos?.slice(0, 7).map((video: any, index: number) => (
            <ImageResource key={index} image={video.src.small} index={index} />
            {imageBlobUrl && <img src={imageBlobUrl} alt="Fetched from blob" width="400" />}
          ))} */}
          {/* {imageBlobUrl &&<ImageResource index={index}  image={imageBlobUrl} />} */}
          {loading ? (
             <div className="loader">
             <img src="https://i.gifer.com/ZZ5H.gif" alt="Loading..." width={60} />
           </div>
          ) : ( 
          <div>
             { imageBlobUrls?.slice(0, 7)?.map((blobUrl, index) => (
              <ImageResource index={index} image={blobUrl} imageName={imageName[index]}/>
            ))}
          </div>
            )
        }
           
   
        </div>
      </div>

    </>
  );
});
