import { useEffect, useState } from 'react';
import Header from '../Components/Header';
import { fetchFile } from '@ffmpeg/util';
import { getTemplate } from '../services/getTemplate';
import SavedVideo from '../Components/static/SavedVideo';
import { StoreContext } from '../store/StoreContext';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const [fileData, setFileData] = useState<any>(null);
  const [tempData, setTempData] = useState<any>(null);
  const store = React.useContext(StoreContext);
  const navigate = useNavigate();
  const menuItem = [
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/1.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/2.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/3.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/4.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/5.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/6.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/7.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/8.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/9.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/10.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/11.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/12.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/13.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/14.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/15.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/16.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/17.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/18.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/19.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/20.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
    {
      text: 'Untitle Project',
      subtitle: '2',
      image: '/demo/21.jpeg',
      ratio: '9:16',
      id: '1713540278553',
    },
  ];

  useEffect(() => {
    const getFile = async () => {
      try {
        const response = await fetchFile("videos");
        setFileData(response);
      } catch (error) {
        console.log("Error fetching video data.");
      }
    };
    const getTemp = async () => {
      try {
        const response = await axios.get(`${API_URL}/media/getMedia`);
        console.log("response templates",response.data);
        setTempData(response.data);
        // store.setEditorElements(response.data[3].mediaItems);
        // toast.success("Get successful!");
      } catch (error) {
        console.error('Error uploading file:', error);
        // toast.error("Upload Failed.");
      }
    };
    // const getTemp = async () => {
    //   try {
    //     const response = await getTemplate();
    //     console.log("response temp", response);
    //     setTempData(response);
    //   } catch (error) {
    //     console.log("Error fetching template data.");
    //   }
    // };

    getFile();
    getTemp();
  }, []);
  const handleClick = (item:any) => {
    store.setMaxTime(Number(10) * 1000);
    // store.removeAllEditorElements();
    console.log("items",item);
    if(tempData)
    // store.addEditorElement(tempData[item]?.mediaItems);
    navigate("/editor", { state: { item } });
  };
  // console.log("template data", tempData)
  return (
    <>
      <Header isEdit={false} />

      <div>
        <p className="text-center text-5xl mt-4 break-normal">
          Create beautiful videos for your social <br /> media campaign
        </p>
        <p className="text-center mt-2">
          Pick a template and create a design in just a few clicks!
        </p>
      </div>
      <div >
        <SavedVideo />
      </div>
      {/* <div className='px-5 mt-5'>
        <h1 className='text-xl font-semibold'>Templates</h1>
      </div>
      <div className="max-w-[1640px] mx-auto p-4 pb-12 grid md:grid-cols-3 gap-6">
        {tempData?.map((item: any, index: any) => {
          return (
            <div key={index} className="rounded-xl">
              <div className="w-full h-full bg-gray-200 border-black border-2 rounded-xl text-black py-4 cursor-pointer" onClick={()=>handleClick(index)}>
                <p className="font-bold text-2xl px-2">Template {index+1}</p>
                
              </div>
            </div>
          );
        })}
      </div> */}
      <div className="max-w-[1640px] mx-auto p-4 pb-12 grid md:grid-cols-3 gap-6">
        {menuItem.map(({ text, subtitle, image, ratio, id }, index) => {
          return (
            <div key={index} className="rounded-xl relative">
              <div className="absolute w-full h-full bg-black/50 rounded-xl text-white">
                <p className="font-bold text-2xl px-2 pt-4">{text} </p>
                <p className="px-2">{subtitle}</p>
                <p className="px-2">{ratio}</p>
                <p className="px-2">{id}</p>
              </div>
              <img
                className="max-h-[160px]  md:max-h-[200px] w-full object-cover rounded-xl"
                src={image}
                alt="/"
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Dashboard;
