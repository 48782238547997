import React from "react";
import { StoreContext } from "../store/StoreContext";
import { observer } from "mobx-react";
import { ExportVideoPanel } from "./panels/ExportVideoPanel";
import { AnimationsPanel } from "./panels/AnimationsPanel";
import { AudioResourcesPanel } from "./panels/AudioResourcesPanel";
import { FillPanel } from "./panels/FillPanel";
import { ImageResourcesPanel } from "./panels/ImageResourcesPanel";
import { TextResourcesPanel } from "./panels/TextResourcesPanel";
import { VideoResourcesPanel } from "./panels/VideoResourcesPanel";
import { EffectsPanel } from "./panels/EffectsPanel";
import Templates from "./static/Templates";
import DragAndDropUpload from "./static/DragAndDropUpload";
import Video from "./static/Video";
export const Resources = observer((props:any) => {
  const store = React.useContext(StoreContext);
  const selectedMenuOption = store.selectedMenuOption;
  const scriptContent=props.script;
  const itemIndex=props.itemIndex;
  return (
    <div className="bg-slate-200 h-full">
      {selectedMenuOption === "Video" ? <VideoResourcesPanel script={scriptContent} /> : null}
      {selectedMenuOption === "Audio" ? <AudioResourcesPanel /> : null}
      {selectedMenuOption === "Image" ? <ImageResourcesPanel script={scriptContent}/> : null}
      {selectedMenuOption === "Text" ? <TextResourcesPanel /> : null}
      {selectedMenuOption === "Animation" ? <Video /> : null}
      {selectedMenuOption === "Effect" ? <EffectsPanel /> : null}
      {selectedMenuOption === "Export" ? <ExportVideoPanel  itemIndex={itemIndex}/> : null}
      {selectedMenuOption === "Fill" ? <FillPanel /> : null}
    </div>
  );
});
