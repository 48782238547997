"use client";
import React, { useEffect, useState } from "react";
import { StoreContext } from "../../store/StoreContext";
import { useSelector, UseSelector } from "react-redux";
import { observer } from "mobx-react";
import { RootState } from "../../store";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;
export const ExportVideoPanel = observer((props: any) => {
  const[length,setLength]=useState(0);
  const[unit,setUnit]=useState("");
  const store = React.useContext(StoreContext);
  const [tempData, setTempData] = useState<any>(null);
  const videoLength = useSelector((state: RootState) => state.script.videoLength);
  const videoLength2 =store.maxTime / 1000;
  const aspectRatio = useSelector((state: RootState) => state.script.aspectRatio);
  console.log("length in export",videoLength);
  console.log("aspect in export",aspectRatio);
  const itemIndex=props.itemIndex;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const time = unit === 'min' ? length * 60 : length;
    store.setMaxTime(Number(time) * 1000); // Assuming store.setMaxTime exists and is correctly imported
    store.setAspectRatio(aspectRatio); // Assuming store.setMaxTime exists and is correctly imported
  };
const userId=Number(localStorage.getItem('userId'));
  const parseVideoLength = (videoLength: string): { length: number, unit: string } => {
    const match = videoLength?.match(/(\d+)\s*(\w+)/);
    if (!match) {
      throw new Error('Invalid video length format');
    }
    return {
      length: parseInt(match[1], 10),
      unit: match[2],
    };
  };
  useEffect(() => {
    if(videoLength){
    const response=parseVideoLength(videoLength);
    setLength(response.length);
    setUnit(response.unit);
    console.log("length response",response);
    }
  }, []);

  const saveTemplate = async () => {

    console.log("canvas elements",store.editorElements);
    // try {
    //   const response = await axios.post(`${API_URL}/media/saveMedia`, {
    //     mediaItems:store.editorElements,
    //     userId
    //   });
    //   console.log('File uploaded successfully:', response.data);
    //   toast.success("Upload successful!");
    // } catch (error) {
    //   console.error('Error uploading file:', error);
    //   toast.error("Upload Failed.");
    // }
  };

  const handleGet = async () => {
    try {
      const response = await axios.get(`${API_URL}/media/getMedia`);
      console.log("response templates",response.data);
      setTempData(response.data);
      // store.setEditorElements(response.data[itemIndex].mediaItems);
      toast.success("Get successful!");
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error("Get Failed.");
    }
  };

  const handleSave = async () => {
    try {
      // Set the playing state
      store.setPlaying(true);
  
      // Wait for the saveCanvasToLocal method to complete
      await store.saveCanvasToLocal();
  
      // Show the success toast message after successful saving
      toast.success("Video Saved Successfully");
    } catch (error) {
      // Handle any errors if saveCanvasToLocal fails
      toast.error("Failed to save video");
    }
  };

  return (
    <>
      <div className="text-sm px-[16px] pt-[16px] pb-[8px] font-semibold">
        Export
      </div>
      {/* Set max time from number input */}
      <div className="px-[16px]">
        <div className="flex flex-row items-center my-2">
          <div className="text-xs font-semibold mr-2">Video Length:</div>
          {/* <input
            type="number"
            className="rounded text-center border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500 max-w-[50px] mr-2"
            value={store.maxTime / 1000}
            onChange={(e) => {
              const value = e.target.value;
              store.setMaxTime(Number(value) * 1000);
            }}
          /> */}
          <input
            type="number"
            disabled
            className="rounded text-center border-slate-200 placeholder-slate-400 contrast-more:border-slate-400 contrast-more:placeholder-slate-500 max-w-[50px] mr-2"
            value={length || videoLength2}
            onChange={handleChange}
          />
          <div>{unit}</div>
        </div>
        <div className="flex flex-row items-center my-2">
          <div className="text-xs font-semibold mr-2">Canvas Resolution:</div>
          <div className="text-xs mr-2">{aspectRatio}</div>
        </div>
      </div>
      {/*  Format selection with radio button */}
      <div className="px-[16px]">
        <div className="text-xs font-semibold mr-2">Video Format:</div>
        <div className="flex flex-row items-center my-2">
          <input
            type="radio"
            className="mr-2"
            name="video-format"
            value="mp4"
            checked={store.selectedVideoFormat === "mp4"}
            onChange={(e) => {
              store.setVideoFormat("mp4");
            }}
          />
          <div className="text-xs mr-2">MP4</div>
          <input
            type="radio"
            className="mr-2"
            name="video-format"
            value="gif"
            checked={store.selectedVideoFormat === "webm"}
            onChange={(e) => {
              store.setVideoFormat("webm");
            }}
          />
          <div className="text-xs mr-2">webm</div>
        </div>
      </div>

      <button
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded-lg m-4"
        onClick={() => {
          store.handleSeek(0);
          store.setSelectedElement(null);
          setTimeout(() => {
            store.setPlaying(true);
            store.saveCanvasToVideoWithAudio();
          }, 1000);
        }}
      >
        Export Video 
      </button>
      <button
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded-lg m-4"
        onClick={() => {
          store.handleSeek(0);
          store.setSelectedElement(null);
          setTimeout(() => {
            handleSave();
          }, 1000);
        }}
      >
        Save Video 
      </button>
      {/* <button
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded-lg m-4"
        onClick={saveTemplate}
      >
        Save Template 
      </button>
      <button
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-1 rounded-lg m-4"
        onClick={handleGet}
      >
        Get Template 
      </button> */}
      <ToastContainer />
    </>
  );
});
