import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
console.log(API_URL);

export const fetchScript = async (payload: any) => {
  return await axios
    .post(`${API_URL}/openai/generateScript`, {
      data: payload,
    })
    .then((response) => {
      return response.data.response.choices;
    });
};
