"use client";
import React, { useEffect, useState } from "react";
import { EditorElement } from "../../types";
import { StoreContext } from "../../store/StoreContext";
import { observer } from "mobx-react";
import DragableView from "./DragableView";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

export const TimeFrameView = observer((props: { element: EditorElement }) => {
  const snippet = useSelector((state: RootState) => state.snippet.snippets);
  const store = React.useContext(StoreContext);
  const [result, setResult] = useState('')
  const { element } = props;
  const disabled = element.type === "audio";
  const isSelected = store.selectedElement?.id === element.id;
  const bgColorOnSelected = isSelected ? "bg-slate-800" : "bg-slate-600";
  const disabledCursor = disabled ? "cursor-no-drop" : "cursor-ew-resize";
  console.log("snip in timeline", snippet)
  console.log("element snip", element.snip)
  const snip = element.snip;
  useEffect(() => {
    const findSnippetWithElement = (snippets: any, element: any) => {
      return snippets.find((snippet: string | any[]) => snippet.includes(element));
    };

    const filteredsnip = findSnippetWithElement(snippet, snip);
    console.log("filteredsnip", filteredsnip);
    setResult(filteredsnip);
  }, [snippet])
  return (
    <div
      onClick={() => {
        store.setSelectedElement(element);
      }}
      key={element.id}
      className={`relative width-full h-[25px] my-2 ${isSelected ? "border-2 border-indigo-600 bg-slate-200" : ""
        }`}
    >
      <DragableView
        className="z-10"
        value={element.timeFrame?.start}
        total={store.maxTime}
        disabled={disabled}
        onChange={(value) => {
          store.updateEditorElementTimeFrame(element, {
            start: value,
          });
        }}
      >
        <div
          className={`bg-white border-2 border-blue-400 w-[10px] h-[10px] mt-[calc(25px/2)] translate-y-[-50%] transform translate-x-[-50%] ${disabledCursor}`}
        ></div>
        {result &&
          <div
            className={`${bgColorOnSelected} h-full w-full ml-24 -mt-7 text-black bg-white text-xs p-2 pb-4 shadow-lg`}
          >
            {result}
          </div>
        }
      </DragableView>

      <DragableView
        className={disabled ? "cursor-no-drop" : "cursor-col-resize"}
        value={element.timeFrame?.start}
        disabled={disabled}
        style={{
          width: `${((element.timeFrame?.end - element.timeFrame?.start) /
              store.maxTime) *
            100
            }%`,
        }}
        total={store.maxTime}
        onChange={(value) => {
          const { start, end } = element.timeFrame;
          store.updateEditorElementTimeFrame(element, {
            start: value,
            end: value + (end - start),
          });
        }}
      >
        <div
          className={`${bgColorOnSelected} h-full w-full text-white text-xs min-w-[0px] px-2 leading-[25px]`}
        >
          {element.name}
        </div>
        {/* <div
          className={`${bgColorOnSelected} h-full w-full text-black bg-white text-xs min-w-[0px] p-2 leading-[25px]`}
        >
          {snippet[0]}
        </div> */}
      </DragableView>
      <DragableView
        className="z-10"
        disabled={disabled}
        value={element.timeFrame?.end}
        total={store.maxTime}
        onChange={(value) => {
          store.updateEditorElementTimeFrame(element, {
            end: value,
          });
        }}
      >
        <div
          className={`bg-white border-2 border-blue-400 w-[10px] h-[10px] mt-[calc(25px/2)] translate-y-[-50%] transform translate-x-[-50%] ${disabledCursor}`}
        ></div>
      </DragableView>
    </div>
  );
});
