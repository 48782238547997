import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
//Icons
// import { AiOutlineTwitter } from 'react-icons/ai';
// import { BiLogoFacebook } from 'react-icons/bi';
import { BiLogoGoogle } from 'react-icons/bi';

//Interfaces
import { LoginForm } from '../Interface/Login.interface';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;
const Login = () => {
  //Variables
  const [loginForm, setLoginForm] = React.useState<Partial<LoginForm>>({});
  let navigate = useNavigate();

  //functions
  const handleLogin = async () => {
    try {
      const { email, password } = loginForm;

      const response = await axios.post(`${API_URL}/users/signin`, {
        email,
        password,
      });
      localStorage.setItem('jwt',response.data.token);
      localStorage.setItem('userId',response.data.user.id);
      toast.success('Login Successfull')
        navigate('/dashboard');
     
    } catch (error: any) {
      toast.error(error, {
        position: 'top-right',
      });
    }
  };

  return (
    <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
      <div className="md:w-1/3 max-w-sm">
        <img src="/images/logo.png" alt="Vid Snap" />
      </div>
      <div className="md:w-1/3 max-w-sm">
        <div className="text-center md:text-left">
          <label className="mr-1">Sign in with</label>
          <button
            type="button"
            className="mx-1 h-9 w-9  rounded-full  hover:bg-blue-700 text-white shadow-[0_4px_9px_-4px_#3b71ca]"
            style={{ backgroundColor: '#30A6DF' }}
          >
            <BiLogoGoogle
              size={20}
              className="flex justify-center items-center w-full"
            />
          </button>
        </div>
        <div className="my-5 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
          <p className="mx-4 mb-0 text-center font-semibold text-slate-500">
            Or
          </p>
        </div>
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded"
          type="text"
          placeholder="Email Address"
          value={loginForm?.email}
          onChange={(e) => {
            setLoginForm({ ...loginForm, email: e.target.value });
          }}
        />
        <input
          className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded mt-4"
          type="password"
          placeholder="Password"
          value={loginForm?.password}
          onChange={(e) => {
            setLoginForm({ ...loginForm, password: e.target.value });
          }}
        />
        <div className="mt-4 flex justify-between font-semibold text-sm">
          <label className="flex text-slate-500 hover:text-slate-600 cursor-pointer">
            <input className="mr-1" type="checkbox" />
            <span>Remember Me</span>
          </label>
          <a
            className="text-blue-600 hover:text-blue-700 hover:underline hover:underline-offset-4"
            href="#"
          >
            Forgot Password?
          </a>
        </div>
        <div className="text-center md:text-left">
          <button
            className="mt-4  hover:bg-blue-700 px-4 py-2 text-white uppercase rounded text-xs tracking-wider"
            type="submit"
            onClick={handleLogin}
            style={{ backgroundColor: '#30A6DF' }}
          >
            Login
          </button>
        </div>
        <div className="mt-4 font-semibold text-sm text-slate-500 text-center md:text-left">
          Don&apos;t have an account?{' '}
          <Link
            className="text-red-600 hover:underline hover:underline-offset-4"
            to='/register'
          >
            Register
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Login;
