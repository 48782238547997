import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Services
import { fetchScript } from "../services/generateScript.service";

//Reducers
import { saveScript, saveLength, saveAspect } from "../store/script/scriptSlice";
import { useDispatch } from "react-redux";

//Header
import Header from "../Components/Header";
import { StoreContext } from "../store/StoreContext";

const CreateNewProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = React.useContext(StoreContext);
  const [length, setLength] = useState(0);
  const[unit,setUnit]=useState("");
  const [state, setState] = useState({
    youtubeCampain: "",
    aspectRatio: "",
    videoLength: "",
    script: "",
  });

  const createScriptRow: number = 8;

  const parseVideoLength = (videoLength: string): { length: number, unit: string } => {
    const match = videoLength?.match(/(\d+)\s*(\w+)/);
    if (!match) {
      throw new Error('Invalid video length format');
    }
    return {
      length: parseInt(match[1], 10),
      unit: match[2],
    };
  };
  const Vidlength = state.videoLength
  const aspect = state.aspectRatio
  // useEffect(() => {
  //   const response = parseVideoLength(Vidlength);
  //   setLength(response.length);
  //   console.log("length response", response);
  // }, []);

  console.log("video length", state.videoLength);
  console.log("aspect ratio", state.aspectRatio);
  const handleScript = async () => {
    let response = await fetchScript(state);

    dispatch(saveScript(response));
    dispatch(saveLength(Vidlength));
    dispatch(saveAspect(aspect));
    store.setAspectRatio(aspect);
    const time = unit === 'min' ? length * 1000 * 60 : length * 1000;
    store.setMaxTime(time);

    navigate("/script");
  };

  const isFormValid = () => {
    const { youtubeCampain, aspectRatio, videoLength, script } = state;
    return youtubeCampain && aspectRatio && videoLength && script;
  };

  const [videoLengths, setVideoLengths] = useState([
    "5 sec",
    "10 sec",
    "15 sec",
    "20 sec",
    "25 sec",
    "30 sec",
    "1 min",
    "3 min",
    "5 min",
    "10 min",
    "15 min",
    "20 min",
    "25 min",
    "30 min",
    "45 min",
    "60 min",
  ]);

  return (
    <>
      <Header isEdit={false} />

      <div className="container flex-auto content-center">
        {/*header*/}
        <div className=" items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t ">
          <h6 className="text-2xl text-black">New Project</h6>
        </div>
        {/*body*/}
        <div className="relative p-6 flex-auto">
          {/* Project Name */}
          <div id="project-name">
            <div style={{ marginTop: "13px", marginBottom: "12px" }}>
              <label className="text-black text-left float-left">
                Project Name
              </label>
            </div>
          </div>

          <div id="youtube-campaign">
            <div className="mb-6">
              <label
                htmlFor="confirm_password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Youtube Campaign
              </label>
              <input
                type="text"
                id="confirm_password"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black"
                placeholder="Youtube Campaign"
                onChange={(e) => {
                  setState({
                    ...state,
                    youtubeCampain: e.target.value,
                  });
                }}
                required
              />
            </div>
          </div>

          {/* Aspect Ratio */}
          <div id="aspect-ratio">
            <div style={{ marginTop: "13px", marginBottom: "12px" }}>
              <label className="text-black text-left float-left">
                Aspect Ratio
              </label>
            </div>
            <ul className="grid w-full gap-6 md:grid-cols-7">
              <li>
                <input
                  type="radio"
                  id="16:9"
                  name="hosting"
                  value="16:9"
                  className="hidden peer"
                  onChange={(e) => {
                    setState({
                      ...state,
                      aspectRatio: e.target.value,
                    });
                  }}
                  required
                />
                <label
                  htmlFor="16:9"
                  className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:sky-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400"
                >
                  <div className="block">
                    <div className="w-full text-lg font-semibold">16:9</div>
                  </div>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="9:16"
                  name="hosting"
                  value="9:16"
                  className="hidden peer"
                  onChange={(e) => {
                    setState({
                      ...state,
                      aspectRatio: e.target.value,
                    });
                  }}
                />
                <label
                  htmlFor="9:16"
                  className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:sky-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400"
                >
                  <div className="block">
                    <div className="w-full text-lg font-semibold">9:16</div>
                  </div>
                </label>
              </li>
              <li>
                <input
                  type="radio"
                  id="1:1"
                  name="hosting"
                  value="1:1"
                  className="hidden peer"
                  onChange={(e) => {
                    setState({
                      ...state,
                      aspectRatio: e.target.value,
                    });
                  }}
                />
                <label
                  htmlFor="1:1"
                  className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:sky-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400"
                >
                  <div className="block">
                    <div className="w-full text-lg font-semibold">1:1</div>
                  </div>
                </label>
              </li>
            </ul>
          </div>

          {/* Video Length */}
          <div id="video-length">
            <div style={{ marginTop: "13px", marginBottom: "12px" }}>
              <label className="text-black text-left float-left">
                Video Length
              </label>
            </div>
            <br />
            <ul className="flex flex-row flex-wrap gap-2 mt-3">
              {videoLengths.map((videoLength, index) => (
                <li key={index}>
                  <label
                    onClick={(E) => {
                      setState({
                        ...state,
                        videoLength: videoLength,
                      });
                      const response = parseVideoLength(videoLength);
                      setLength(response.length);
                      setUnit(response.unit);
                    }}
                    htmlFor="3min"
                    className={`inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:sky-400 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 ${videoLength === state.videoLength && "border-blue-600"
                      }`}
                  >
                    <div className="block">
                      <div className="w-full text-lg font-semibold">
                        {videoLength}
                      </div>
                    </div>
                  </label>
                </li>
              ))}
            </ul>
          </div>

          {/* Create Script */}
          <div id="create-script">
            <div style={{ marginTop: "13px", marginBottom: "12px" }}>
              <label className="createScript">Create Script</label>
              <div className="w-full mb-4 border border-gray-200  bg-gray-50 ">
                <div className="px-4 py-2 bg-white  ">
                  <label htmlFor="comment" className="sr-only">
                    Your comment
                  </label>
                  <textarea
                    id="comment"
                    rows={createScriptRow}
                    className="w-full px-0 text-sm  bg-white  dark:placeholder-gray-400 text-black border-transparent focus:border-transparent focus:ring-0"
                    placeholder='Example: "write a short SEO marketing" script'
                    onChange={(e) => {
                      setState({
                        ...state,
                        script: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>

                <div className="flex items-center justify-between px-3 py-2 ">
                  <button
                    type="submit"
                    className={`inline-flex items-center py-2.5 px-4 text-sm font-medium text-right text-white focus:ring-4 focus:ring-blue-200 float-right ${isFormValid() ? "bg-sky-400" : "bg-gray-300"
                      }`}
                    onClick={handleScript}
                    disabled={!isFormValid()}
                  >
                    Generate Script
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewProject;
