import { createSlice,PayloadAction } from '@reduxjs/toolkit';

interface ScriptState {
  script: object;
  videoLength: string;
  aspectRatio: string;
}

const initialState: ScriptState = {
  script: {},
  videoLength:'',
  aspectRatio:'',
};

const scriptSlice = createSlice({
  name: 'script',
  initialState,
  reducers: {
    saveScript: (state, action: any) => {
      state.script = action;
    },
    saveLength: (state, action: PayloadAction<string>) => {
      state.videoLength = action.payload;
    },
    saveAspect: (state, action: PayloadAction<string>) => {
      state.aspectRatio = action.payload;
    },
  },
});

export const { saveScript,saveLength,saveAspect } = scriptSlice.actions;
export default scriptSlice.reducer;
