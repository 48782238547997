import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchSnippet = async (payload: any) => {
  try {
    const response = await axios.post(`${API_URL}/openai/generateSnippet`, {
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching video:", error);
    throw error;
  }
};
