import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchFile = async (fileType:any) => {
    console.log("file type",fileType)
    const userId = localStorage.getItem('userId');
    try {
      const response = await axios.get(`${API_URL}/file/files/${fileType}`, {
        params: { userId }
    });
        return response.data;
      } catch (error) {
        console.error(`Error fetching ${fileType}:`, error);
        throw error;
      }
  };
